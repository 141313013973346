<template>
  <div>
    <BaseThemeComponent
      v-if="thePage.contents.show_home_hero"
      name="SectionHomeHero"
    />

    <BaseThemeComponent id="home-locations" name="SectionLocations" />
    <BaseThemeComponent
      v-if="thePage.contents.show_home_catering"
      name="CateringCTA"
    />

    <BaseThemeComponent
      v-if="thePage.contents.show_home_instagram"
      name="SectionInstagram"
      section-name="homeInstagram"
    />

    <BaseMenuComponent name="MenuSnackbar" />
  </div>
</template>

<script>
import PageMixin from '@mixins/page'

export default {
  name: 'BubsHome',
  mixins: [PageMixin],
  data() {
    return {}
  },
}
</script>
